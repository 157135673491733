import { useFreebetsStore } from '../stores/useFreebetsStore'
import { useFreespinsStore } from '../stores/useFreespinsStore'

export function useFreeBonuses() {
  const route = useRoute()

  const { notDepositFreebets: freebets } = storeToRefs(useFreebetsStore())
  const { fetchFreebets } = useFreebetsStore()

  const { notDepositFreespins: freespins } = storeToRefs(useFreespinsStore())
  const { fetchFreespins } = useFreespinsStore()

  const totalCount = computed(
    () => freespins.value.length + freebets.value.length,
  )

  function fetchBonuses() {
    fetchFreespins()
    fetchFreebets()
  }

  watch(
    () => route.query.modal,
    () => {
      fetchBonuses()
    },
  )

  return { freespins, freebets, totalCount }
}
